<template>
  <v-container fluid>
    <h1 class="h1">Acompanhamento dos Atendimentos</h1>
    <p></p>
    <v-data-table :loading="isLoading('get:sessions')" :items="tableData" :search="search" :headers="headers"
      :single-expand="singleExpand" :expanded.sync="expanded" show-expand>
      <!-- Filtro -->
      <template v-slot:top>
        <v-row class="mt-2">
          <v-col cols="12">
            <!-- <v-row>
              <v-col class="text-end">
                <v-badge left bordered overlap :value="activeFilters.length > 0" :content="activeFilters.length">
                  <v-btn class="mr-2 elevation-0" text v-on:click="showFilters = !showFilters">
                    <v-icon v-if="!showFilters" left> mdi-filter </v-icon>
                    <v-icon v-else left> mdi-filter-off </v-icon>
                    Filtros
                  </v-btn>
                </v-badge>
                <v-btn class="mr-4 elevation-0" text @click="collapseAll"> Recolher Linhas </v-btn>
                <v-btn class="elevation-0" text @click="getSessions">
                  <v-icon left> mdi-reload </v-icon>
                  atualizar
                </v-btn>
              </v-col>
            </v-row> -->
            <v-expand-transition>
              <v-card outlined v-show="showFilters" dense class="mb-2">
                <h4 class="ml-4 mt-4 blod_color">Pesquisar por: </h4>
                <v-row dense class="py-2 px-4">
                  <v-col cols="2">
                    <v-autocomplete :loading="loading" v-model="objeto.modalidade" outlined label="Modalidade"
                      :items="modalidadesAtivas" item-value="id" item-text="nome" dense clearable/>
                  </v-col>
                  <v-col cols="2">
                    <v-autocomplete :loading="loading" v-model="objeto.operadora" outlined label="Operadora"
                      :items="operadorasAtivas" item-value="id" item-text="nome" dense clearable/>
                  </v-col>
                  <!-- <v-col cols="2">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                    <v-autocomplete v-bind="attrs" v-on="on" :loading="loading" v-model="objeto.prestador" outlined label="Id - Prestador" :items="prestadores" item-value="id" item-text="apelido" dense clearable />
                    </template>
                      <span>Somente Prestador(a) Ativo</span>
                    </v-tooltip>
                  </v-col> -->
                  <v-col cols="2">
                    <v-autocomplete :loading="loading"  item-value="id" item-text="nome" v-model="objeto.paciente" outlined label="Id - Paciente" :items="pacientes"
                      dense clearable />
                  </v-col>
                  <v-col cols="2">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-bind="attrs"  v-on="on" :loading="loading" placeholder="Digite o Nº do Orçamento" item-value="id" item-text="id" v-model="objeto.id" outlined  label="Id - Orçamento" :items="orcamentosAtivas" dense clearable />
                      </template>
                      <span>Somente Orçamento Ativo</span>
                    </v-tooltip>
                  </v-col>
                <!-- </v-row>
                <v-row dense class="py-2 px-4"> -->
                  <v-col cols="2">
                    <v-text-field v-model="objeto.data_inicio" outlined type="date"  dense clearable :rules="requiredField">
                      <template v-slot:label>
                        <span>Data início orçamento <span style="color: red;">*</span></span>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field v-model="objeto.data_fim" outlined type="date"  dense clearable :rules="requiredField">
                      <template v-slot:label>
                        <span>Data término do orçamento <span style="color: red;">*</span></span>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row> 
                <v-card-actions>
                  <v-row dense>
                    <v-col class="text-end">
                      <v-btn class="mr-4 elevation-0" text @click="collapseAll"> Recolher Linhas </v-btn>
                      <v-btn color="red" text class="mr-2" elevation="0" @click="clearFilters"> limpar Pesquisa </v-btn>
                      <!-- <v-btn color="success" elevation="0" @click="applyFilters"> Pesquisar </v-btn> -->
                       <v-tooltip top :disabled="isFormValid">
                        <template v-slot:activator="{ on, attrs }">
                          <span v-on="on" v-bind="attrs">
                            <v-btn :color="isFormValid ? 'success' : 'grey'" :loading="isSubmitting" :disabled="!isFormValid || isSubmitting" @click="applyFilters"> Pesquisar </v-btn>
                          </span>
                        </template>
                        <span>Preencha todos os campos obrigatórios (*) para habilitar o botão.</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-card>
            </v-expand-transition>
            <v-row>
              <v-col cols="6">
                <v-text-field dense outlined v-model="search" label="Pesquisar" class="mx-0" append-icon="mdi-magnify" clearable />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </template>
      
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="py-4 grey darken-2 white--text text-start">
          <v-row dense>
            <v-col :cols="6">
              <ResumoExpand 
                :url="`atendimentos/evolucoes-simples/?orcamento=${item.id}&paciente=${item.paciente_id}`" 
                :view="'produtividade'"
              />
            </v-col>
            <v-col :cols="6">
              <AtendExpand 
                :url="`atendimentos/plano-terapeutico-full/?orcamento=${item.id}&paciente=${item.paciente_id}&data_inicio=${objeto.data_inicio}&data_fim=${objeto.data_fim}`" 
                :view="'plano_terapeutico'"
              />
            </v-col>
          </v-row> 
          <v-row dense>
            <ProducExpand 
              :url="`atendimentos/groupby-producao-profissional/?orcamento=${item.id}&paciente=${item.paciente_id}`" 
              :view="'plano_terapeutico'"
            />
          </v-row>
          <v-row dense>
            <ProcedExpand 
              :url="`atendimentos/sessoes_vw/?orcamento=${item.id}&paciente=${item.paciente_id}`" 
              :view="'procedimentos'"
            />
          </v-row>
        </td>
      </template>
      <template v-slot:[`item.data_inicio`]="{ item }">
        {{ item.data_inicio | formatDatabr }}
      </template>
      <template v-slot:[`item.senha_data_inicio`]="{ item }">
        {{ item.senha_data_inicio | formatDatabr }}
      </template>
      <template v-slot:[`item.senha_data_validade`]="{ item }">
        {{ item.senha_data_validade | formatDatabr }}
      </template>
      <template v-slot:[`item.data_nascimento`]="{ item }">
        {{ item.data_nascimento | formatDatabr }}
      </template>
      <template v-slot:[`item.paciente`]="{ item }">
        <!-- <router-link :to="`cadastro/${parseInt(item.paciente_id)}`" target="_blank"> -->
          {{ item.paciente_id }} - {{ item.paciente }}
        <!-- </router-link> -->
      </template>
      <template v-slot:[`item.data_fim`]="{ item }">
        {{ item.data_fim | formatDatabr }}
      </template>
      <template v-slot:[`item.modalidade`]="{ item }">
        {{ parseModalidade(item.modalidade).nome }}
      </template>
      <template v-slot:[`item.id`]="{ item }">
        <Saldo 
          :url="'uteis/filtro-condicional/?table=producao-orcamentos'"  
          :urlv="`atendimentos/sessoes_vw/?orcamento=${item.id}&paciente=${item.paciente_id}`"
          :lista="[]"
          :objeto-current="item"/>
        <!-- <v-chip :to="`sessao/${parseInt(item.id)}`" target="_blank" class="ma-1 white--text" :color="item.saldo.cor">
          {{ item.id }} - {{ formatNumber(item.saldo.text) }}
          <v-icon class="ml-2 mr-1" right> {{ item.saldo.icone }} </v-icon>
        </v-chip> -->
      </template>  
      <template v-slot:no-data>
        <v-alert v-if="dataFetched" class="custom-alert" >
        Nenhum dado disponível para os critérios de pesquisa selecionados.
        </v-alert>
      </template>
    </v-data-table>
    <!-- <div v-if="!tableData.length && !isLoading('get:sessions')">
      <v-alert color="error" icon="mdi-alert" outlined>
        <div>
          Nenhum dado disponível para exibir.
        </div>
      </v-alert>
    </div> -->
  </v-container>
</template>

<script>
import api from "@/http";
import UtilsFunc from "../../service/utilsFunc";
import { mapGetters } from "vuex";
import '../../assets/css/styles.css'

export default {
  name: "Atendimentos",
  components:{
    AtendExpand:()=>import('./componentes/AtendExpand.vue'),
    ResumoExpand:()=>import('./componentes/ResumoExpand.vue'),
    ProcedExpand:()=>import('./componentes/ProcedExpand.vue'),
    ProducExpand:()=>import('./componentes/ProducExpand.vue'),
    Saldo:()=>import('./componentes/Saldo.vue'),
  },
  data: () =>
    UtilsFunc.withCRUDUtils({
      expanded: [],
      singleExpand: false,
      loading: false,
      items: [],
      search: "",
      prestador: "",
      prestadores: [],
      orcamentos: [],
      pacientes: [],
      modalidades: [],
      operadoras: [],
      nome_paciente: "",
      objeto:{},
      activeFilters: [],
      dataFetched: false,
      showFilters: true,
      isSubmitting: false,
      requiredField: [UtilsFunc.isEmptyField('Obrigatório')],
      data_inicio_1: UtilsFunc.setDateFormatyyyyMMdd(360),
      data_inicio_2: UtilsFunc.setDateFormatyyyyMMdd(),
      headers_row: [
        { text: "Procedimento", value: "procedimento" },
        { text: "Profissional", value: "" },
        { text: "Evoluções (EV_NP)", align:"center" },
        { text: "Evoluções lançadas", value: "evoluidos", align: "center"},
        { text: "Assinadas / Validadas", value: "assinados", align: "center" },
      ],
      headers: [
        { text: "Convênio", cellClass: "text-start",  align: "start", sortable: true, value: "convenio", },
        { text: "Modalidade", cellClass: "center",  align: "start", sortable: true, value: "modalidade", },
        { text: "ID - Orçamento", cellClass: "center",  align: "start", sortable: true, value: "orcamento", },
        { text: "ID - Paciente", align: "start", sortable: true, value: "paciente", },
        { text: "Data nascimento", align: "center", sortable: true, value: "data_nascimento", },
        { text: "Data inicio orçamento",  align: "center", sortable: true, value: "data_inicio", },
        { text: "Data término orçamento", align: "center", sortable: true, value: "data_fim", },
        // { text: "Guia prestador", align: "center", sortable: true, value: "nr_guia_prestador" },
        // { text: "Senha", align: "center", sortable: true, value: "senha" },
        // { text: "Guia Operadora", align: "center", sortable: true, value: "nr_guia_operador", },
        // { text: "Data início senha", align: "center", sortable: true, value: "senha_data_inicio", },
        // { text: "Data validade senha", align: "center", sortable: true, value: "senha_data_validade", },
        { text: "ID do Orçamento - Status", align: "center", sortable: true, value: "id", },
      ],
    }),
  methods: {
    async getSessions(params) {
      const LOADING_NAME = "get:sessions";
      this.setLoading(LOADING_NAME);
      try {
        const { data } = await api(`atendimentos/sessoes/?modulo=atendimentos&empresa=${this.companyData.id}&${params}`);
        this.tableData = data;
        // console.log('GetSessions ---> ',this.tableData)
      } catch (error) {
        this.$errorHandler(error);
        this.$toast.error(`Desculpe. Apresentou algum erro. ${error}`);
      } finally {
        this.setLoading(LOADING_NAME, true);
        this.dataFetched = true;
      }
    },

    async getStart(array, url) {
      this.loading =  true;
      try {
        const { data } = await api.get(url);
        this[array] = data
      } catch (error) {
        this.$errorHandler(error);
        this.$toast.error(`Desculpe. Apresentou algum erro. ${error}`);
      } finally {
        this.loading =  false;
      }
    },
    conta_evol(val) {
      const groupedData = val.reduce((acc, item) => {
        const key = `${item.sessao}-${item.profissional}`;
        acc[key] = acc[key] || {
          sessao: item.sessao,
          profissional: item.profissional,
          count: 0,
        };
        acc[key].count++;
        return acc;
      }, {});

      const result = Object.values(groupedData);

      return result;
    },
    clearFilters() {
      this.objeto = {};
      // this.getSessions('');

    },
    applyFilters() {
      if (!this.objeto.data_inicio || !this.objeto.data_fim) {
        this.$toast.error('Por favor, preencha as datas de Início e Término do período do Orçamento para prosseguir com a pesquisa.');
        return;
      }
      this.isSubmitting = true;
      if (this.isFilterApplied()) {
        const filters = { ...this.objeto };
       
        let params = '';
        Object.keys(filters).forEach(k => {
          if(filters[k]) {
            params += `&${k}=${filters[k]}`;
          }
        });

        this.getSessions(params);
        this.isSubmitting = false;
      } else {
        this.$toast.error('Por favor, preencha pelo menos um filtro para pesquisar.');
      }
    },
    isFilterApplied() {
      return Object.keys(this.objeto).some(key => this.objeto[key] != null && this.objeto[key] !== '');
    },
    // Usado para fixar os dois digitos após a virgula
    formatNumber(value) {
      return parseFloat(value).toFixed(2);
    },
    // Usado para recolher todas as linhas 
    collapseAll() {
      this.expanded = [];
    },
    expandOrcamento(item){
      console.log(item)
    },
    parseModalidade(modalidadeString) {
      try {
        return JSON.parse(modalidadeString);
      } catch (e) {
        console.error('Erro ao parsear modalidade:', e);
        return {};
      }
    }
  },
  mounted() {
    // this.getprestadores();
    // this.getSessions()
    this.getStart('prestadores', 'contas/app-usuarios-id-nome/')
    this.getStart('pacientes', 'pacientes/app-pacientes/')
    this.getStart('orcamentos', 'atendimentos/app-orcamentos/')
    this.getStart('operadoras', 'operadoras/app-operadoras/')
    this.getStart('modalidades', 'uteis/app-modalidades/')
  },

  computed: {
    ...mapGetters(['companyData']),
    sessions() {
      const filterBy = UtilsFunc.createFilter(this.activeFilters);
      return this.tableData.filter(filterBy);
    },
    guideIds() {
      return Array.from(new Set(this.tableData.map((row) => row.id)));
    },
    patientIds() {
      return Array.from(new Set(this.tableData.map((row) => row.paciente.id)));
    },
    providerIds() {
      return Array.from(
        new Set(
          this.tableData.reduce((acc, row) => {
            acc.push(
              ...row.paciente.equipe_multi_disciplinar.map((item) => item.id)
            );
            return acc;
          }, [])
        )
      );
    },
    modalidadesAtivas() {
      return this.modalidades.filter(m => m.ativo);
    },
    operadorasAtivas() {
      return this.operadoras.filter(m => m.ativo);
    },
    orcamentosAtivas() {
      return this.orcamentos.filter(o => o.ativo);
    },
     isFormValid() {
      // Verifica se todos os campos obrigatórios estão preenchidos para poder habilitar o botão de Salvar
      return !!( this.objeto.data_inicio && 
        this.objeto.data_fim);
    },
    requiredAsterisk() {
      return '<span style="color: red;">*</span>';
    },
  },
};
</script>

<style>
.custom-alert {
    background-color: white !important;
    color: red !important;
}
</style>
